<template>
  <div class="course_preview_center">
    <img src="@/assets/login_logo.png" alt="" class="accountpanel_logo">
    <div class="course_preview_main">
      <div class="form_head">
        <img src="@/assets/svg/logo_white_small.svg" alt="" class="form_img">
        <div class="form_head_content">
          <p class="title fontsize_14 color_fff">{{openDetail.title}}</p>
          <p class="fontsize_12 color_fff">来自{{openDetail.username}}</p>
        </div>
      </div>
      <div class="form_body">
        <label for="codeInput" class="fontsize_14 color_26">{{openDetail.open_way === 'teloremail'?'请输入手机号/邮箱':'请输入访问码：'}}</label>
        <div class="form_body_inner">
          <input type="text" placeholder="" id="codeInput" class="codeInput" @input="handleInput" @keydown.enter="handleEnter">
          <button @click="visitPreview" type="button" class="code_btn">确定</button> 
        </div>
        <p class="error-tips">{{errorTips}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getShareCourseOfPreview , putShareCourseOfPreview} from '@/libs/api/share'
export default {
  data() {
    return {
      codeValue:"",
      errorTips:"",
      openDetail: {
        course_url:"",
        open_way:"",
        p:"",
        username:"",
        title: ""
      },
    };
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    handleEnter(e) {
      this.visitPreview();
    },
    handleInput(e) {
      this.codeValue = e.target.value;
    },
    getdetail() {
      let { teloremail, p } = this.$route.query;
      getShareCourseOfPreview(
        {p}
      ).then(res => {
        if(res.errorcode == 0) {
          this.openDetail = res.data;
        }else {
          let obj = {
            1:"error",
            2:"inexistence",
            3: "expire",
            4: "upperlimit"
          };
          // this.$router.push( `/invalid/${obj[res.errorcode]}` );
          this.$router.push({
            name: 'Invalid',
            params: {
              type: obj[res.errorcode],
              msg: res.msg
            }
          });
        }
      })
    },
    visitPreview() {
      let { p,  open_way } = this.openDetail;
      let params = {
        p,
        type: open_way,
      };
      this.errorTips = "";

      if(!this.codeValue) {
        this.$message.warning(`请输入${open_way === 'code' ? '访问码':'手机号/邮箱'}`);
        return;
      }
      if(open_way === 'code') {
        params['code'] = this.codeValue;
      }
      if(open_way === 'teloremail') {
        params['teloremail'] = this.codeValue;
      }
      putShareCourseOfPreview(params).then(res => {
        if(res.errorcode == 0) {
          let url = res.data.course_url;
          //当前跳转不对，不要显示路由
          // this.directOpen( { p, path: "/enview" } );
          this.$router.push({
            name:'EnView',
            params: {
              websrc: url
            },
          });
        }else {
          this.errorTips = res.msg;
        }
      })
    },
    directOpen({ p, path }) {
      let { href } = this.$router.resolve({
        path: path,
        query:{
          p: p
        }
      });
      window.open(href, "_blank");
    },
  }
}
</script>

<style lang="less">
  .course_preview_center {
    width: 100%;
    height: 100%;
    background-image: url(~@/assets/preview_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .accountpanel_logo {
    position: fixed;
    top: 40px;
    left: 40px;
    height:41px;
  }
  .course_preview_main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 198px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
  }
  .form_head {
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 50px;
    background-image: linear-gradient(94deg, #FF366F 0%, #E74362 100%);
    .form_img {
      width: 26px;
    }
    .form_head_content {
      padding-left: 10px;
    }
    .title {
      margin-bottom: 2px;
    }
  }
  .form_body {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    label {
      margin-bottom: 8px;
    }
    .form_body_inner {
      display: flex;
      align-items: center;
    }
    .codeInput {
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      width: 439px;
      height: 32px;
      text-indent: 1em;
      font-size: 14px;
    }
    .code_btn {
      margin-left: 12px;
      width: 60px;
      height: 32px;
      border-radius: 4px;
      background: #E74362;
      border: none;
      outline: none;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
    }
  }

  .error-tips {
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 12px 12px;
    background-image: url(~@/assets/icon-warning1.png);
    padding-left: 16px;
    margin-top: 8px;
    font-size: 12px;
    color: #FF0031;
    letter-spacing: 0;
  }
</style>